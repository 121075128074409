import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import LogoSanJorge from "../../images/logo-san-jorge.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  sendKmsRedux,
  startAcumuladorAño,
  startAcumuladorMarca,
  startAcumuladorModelo,
  startAcumuladorVersion,
  startGetBrands,
  startGetModels,
  startGetVersion,
  startLoading,
} from "../../actions/pasosCotizar";
import { isTablet } from "../../actions/pasosTablet";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export const Success = () => {
  useEffect(() => {
    window.location.href = "https://cotizador.landing.auta.com.ar/";
  }, []);
  let isPageWide = useMediaQuery("(min-width: 900px)");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToLanding = () => {
    if (isPageWide) {
      dispatch(startAcumuladorAño(undefined));
      dispatch(startAcumuladorMarca(undefined));
      dispatch(startAcumuladorModelo(undefined));
      dispatch(startAcumuladorVersion(undefined));
      dispatch(sendKmsRedux(undefined));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
      navigate("/");
    } else {
      dispatch(isTablet(""));
      dispatch(startAcumuladorAño(""));
      dispatch(startAcumuladorMarca(""));
      dispatch(startAcumuladorModelo(""));
      dispatch(startAcumuladorVersion(""));
      dispatch(sendKmsRedux(""));
      dispatch(startGetBrands([]));
      dispatch(startGetModels([]));
      dispatch(startGetVersion([]));
      dispatch(startLoading(undefined, false, 100));
      navigate("/");
    }
  };

  return (
    <Grid
      direction="row"
      alignItems="center"
      justifyContent="center"
      className="container-cotizar"
    >
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          src={LogoSanJorge}
          alt="logo-san-jorge"
          className="logo-san-jorge"
        />
        <p className="text-cotiza" style={{ textAlign: "center" }}>
          Gracias, le enviaremos una oferta inicial <br /> a su número a la
          brevedad.
        </p>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: "50px" }}
        >
          <Grid item>
            <button
              className="btn-contained"
              type="submit"
              onClick={navigateToLanding}
            >
              Cotizar otro auto
            </button>
          </Grid>
          <Grid>
            <button className="btn-outlined">
              <a href="https://www.chevroletsanjorge.com.ar/">
                Ir a nuestro sitio web
              </a>
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
